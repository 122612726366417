import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import { formatPrice } from '../../utils/stripe-handle'
import {
  CartDispatchContext,
  CartStateContext,
  addToCart,
} from '../contexts/Cart'

export const StoreProductTemplate = ({
  content,
  contentComponent,
  helmet,
  productData,
  isSold,
}) => {
  const { name, gallery = [], featuredImage, stripePrice } = productData
  const ProductContent = contentComponent || Content
  const images = [featuredImage, ...(gallery ?? [])]
  const [isAdded, setIsAdded] = useState(false)
  const dispatch = useContext(CartDispatchContext)
  const { items } = useContext(CartStateContext)
  const [selected, setSelected] = useState(0)

  const handleAddToCart = () => {
    const product = {
      id: stripePrice.id,
      name,
      price: stripePrice.unit_amount,
      currency: stripePrice.currency,
      quantity: 1,
    }
    addToCart(dispatch, product)
    setIsAdded(true)
  }

  useEffect(() => {
    if (items.some((item) => item.name === name)) {
      setIsAdded(true)
    } else {
      setIsAdded(false)
    }
  }, [items.length])

  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1 className="title is-size-2 has-text-weight-semibold is-bold-light">
              {name}
            </h1>
          </div>
        </div>
        <div className="columns">
          <div className="column is-6">
            <PreviewCompatibleImage imageInfo={images[selected]} />
          </div>
          <div className="column container">
            <div className="columns is-mobile">
              {images.map((image, index) => (
                <div
                  key={index}
                  className={`column is-mobile image-thumb ${
                    selected === index ? 'is-selected' : ''
                  } is-clickable`}
                  onClick={() => setSelected(index)}
                >
                  <PreviewCompatibleImage imageInfo={image} />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="column is-6">
          <ProductContent content={content} />
        </div>

        <div className="column is-6 is-offset-4">
          <button
            onClick={handleAddToCart}
            style={{
              alignContent: 'flex-start',
              marginRight: '5px',
              verticalAlign: 'sub',
            }}
            className={`button is-black`}
            disabled={isSold || isAdded}
          >
            {!isAdded ? 'ADD TO CART' : '✔ ADDED'}
          </button>
          <span
            className="has-text-weight-medium"
            style={{
              textAlign: 'center',
              verticalAlign: 'sub',
            }}
          >
            {formatPrice(stripePrice.unit_amount)}
          </span>
        </div>
      </div>
    </section>
  )
}

StoreProductTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  helmet: PropTypes.object,
  productData: PropTypes.shape({
    name: PropTypes.string,
    stripePrice: PropTypes.shape({
      id: PropTypes.string.isRequired,
      unit_amount: PropTypes.number.isRequired,
      quantity: PropTypes.number,
      product_id: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
    }).isRequired,
  }),
}

const StoreProduct = ({ data, location }) => {
  const { markdownRemark: product } = data
  const { frontmatter } = product
  return (
    <Layout>
      <StoreProductTemplate
        content={product.html}
        contentComponent={HTMLContent}
        helmet={
          <Helmet titleTemplate="%s | Store">
            <title>{`${product.frontmatter.name}`}</title>
            <meta
              name="description"
              content={`${product.frontmatter.description}`}
            />
          </Helmet>
        }
        productData={frontmatter}
        isSold={location.state?.isSold}
      />
    </Layout>
  )
}

StoreProduct.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default StoreProduct

export const pageQuery = graphql`
  query StoreProductByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        name
        stripePrice {
          id
          unit_amount
          quantity
          product_id
          currency
        }
        date
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        gallery {
          image {
            childImageSharp {
              fluid(maxWidth: 768, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
